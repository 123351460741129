<script>
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  name: 'OrderCard',

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('user', ['user']),

    orderId () {
      return this.order?.id
    },

    orderDate () {
      if (this.order?.created_at) {
        return this.order?.created_at
      }

      return ''
    },

    buyerName () {
      return this.order?.buyer_name
    },

    buyerId () {
      return this.order?.account_id
    },

    sellerName () {
      return this.order?.full_name
    },

    sellerId () {
      return this.order?.seller_id
    },

    appType () {
      return ''
    },

    categoryFullName () {
      return this.items?.[0]?.title
    },

    paymentMethod () {
      return this.order?.order_data?.method
    },

    total () {
      return (this.order?.content?.total / 100)?.toFixed(2)
    },

    items () {
      return this.order?.content?.items
    },

    appName () {
      return this.items?.[0]?.brand
    },

    categoryName () {
      return this.order?.content?.items?.[0]?.title?.replace(this.appName + ' ', '').replace(/^\[[^\]]*\] (.*)$/g, '$1')
    },

    productId () {
      return this.order?.order_data?.productId
    },

    income () {
      return ((this.items?.map(item => item.min_price)?.reduce((a, b) => a + b, 0) || 0) / 100).toFixed(2).replace('.00', '')
    },

    fullPrice () {
      return ((this.items?.map(item => item.max_price * (item.amount || 1))?.reduce((a, b) => a + b, 0) || 0) / 100).toFixed(2).replace('.00', '')
    },

    platformCommission () {
      return (+this.fullPrice - +this.income).toFixed(2).replace('.00', '')
    },

    isSeller () {
      return this.order?.seller_id === this.user?.id
    },

    sellerProfileLink () {
      return `https://ru.gaming-goods.com/user/id/${this.order?.seller_id}`
    },

    orderState () {
      return this.order.status
    },

    orderStatusLabelStyles () {
      return {
        [this.$style.status]: true,
        [this.$style.statusOrange]: this.orderState === 'created',
        [this.$style.statusBlue]: this.orderState === 'paid' || this.orderState === 'in_progress',
        [this.$style.statusCyan]: this.orderState === 'fulfilled',
        [this.$style.statusGreen]: this.orderState?.includes('completed'),
        [this.$style.statusRed]: this.orderState?.includes('cancelled')
      }
    },

    orderChatId () {
      return [this.order?.account_id, this.order?.seller_id].sort().join('_')
    },

    orderStatusText () {
      if (!this.orderState) {
        return ''
      }

      const result = {
        created: 'Ожидает оплаты',
        paid: 'Ожидает продавца',
        in_progress: 'В работе',
        fulfilled: 'Ожидает одобрения',
        completed: 'Выполнен',
        completed_with_review: 'Выполнен',
        cancelled: 'Отменен',
        cancelled_with_review: 'Отменен',
        cancelled_before_payment: 'Отменен до оплаты',
        cancelled_after_payment: 'Отменен'
      }
      return result[this.orderState]
    },

    canAccept () {
      return [
        'fulfilled'
      ].includes(this.orderState)
    },

    canCancel () {
      return [
        'paid',
        'fulfilled',
        'in_progress'
      ].includes(this.orderState)
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('chat', ['setChatId', 'setChatName']),

    openChat () {
      this.setChatId(this.orderChatId)
      this.setChatName('Чат')
    },

    async acceptOrder () {
      try {
        await this.$axios.post(`/marketplace/v-order/${this.orderId}/complete`)

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Success',
            text: 'Заказ подтверждён',
          },
        })
      } catch (e) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            title: 'Ошибка',
            text: e,
          },
        })
      }
    },

    async cancelOrder () {
      try {
        await this.$axios.post(`/marketplace/v-order/${this.orderId}/cancel`)

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Success',
            text: 'Заказ отменён',
          },
        })
      } catch (e) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            title: 'Ошибка',
            text: e,
          },
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.card">
    <div :class="$style.top">
      <div>
        <div :class="$style.spaceBetween">
          <div>
            <div :class="$style.orderId__container">#<span :class="$style.orderId">{{ orderId }}</span></div>
            <div :class="orderStatusLabelStyles">
              {{ orderStatusText }}
            </div>
          </div>

          <span :class="$style.date">{{ orderDate }}</span>
        </div>
      </div>
    </div>

    <div :class="$style.bottom">
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          ID Покупателя
        </span>
        <span :class="$style.pairValue">
          {{ buyerId }}
        </span>
      </div>
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          Покупатель
        </span>
        <span :class="$style.pairValue">
          {{ buyerName }}
        </span>
      </div>
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          ID Продавца
        </span>
        <a :class="$style.pairValue" :href="sellerProfileLink">
          {{ sellerId }}
        </a>
      </div>
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          Продавец
        </span>
        <span :class="$style.pairValue">
          {{ sellerName }}
        </span>
      </div>
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          Способ оплаты
        </span>
        <span :class="$style.pairValue">
          {{ paymentMethod }}
        </span>
      </div>
      <div :class="$style.pair">
        <span :class="$style.pairTitle">
          Категория
        </span>
        <span :class="$style.pairValue">
          {{ categoryFullName }}
        </span>
      </div>
      <div :class="$style.buttons">
        <Button is-primary @click="openChat">
          Чат
        </Button>
        <Button v-if="canAccept" is-primary is-green @click="acceptOrder">
          Одобрить
        </Button>
        <Button v-if="canCancel" is-primary is-red @click="cancelOrder">
          Отменить
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.card {
  display: block;
  padding: 12px;
  background: $blue-grey60;
  border-radius: 8px;
}

.top {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  align-items: start;
  border-bottom: 1px solid $blue-grey40;
  padding-bottom: 4px;
  margin-bottom: 12px;
}

.date {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  max-width: 100px;
  text-align: end;
}

.cover {
  border-radius: 8px;

  &__empty {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: $blue-grey10;
  }
}

.orderId {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: $white;

  &__container {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: $blue-grey10;
  }
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 4px 16px;
  background: $white10;
  color: $white;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 100px;
  text-wrap: wrap;

  @media (min-width: $breakpointTablet) {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  &Orange {
    background: $amber60-10;
    color: $amber60;
  }

  &Blue {
    background: $light-blue60-10;
    color: $light-blue50;
  }

  &Cyan {
    background: $cyan60-10;
    color: $cyan40;
  }

  &Green {
    background: $green60-10;
    color: $green50;
  }

  &Red {
    background: $red60-10;
    color: $red50;
  }
}

.names {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  align-items: center;
}

.appName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.categoryName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.price {
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  align-items: start;

}

.pair {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;

  &Double {
    grid-column: 1 / 3;
  }

  &Title {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #90A4AE;
  }

  &Value {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $white;

    &Link {
      color: $primary60;
      text-decoration: underline;
    }

    &Separator {
      margin: 0 4px;
    }
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.item {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: 8px;
  background: $blue-grey60;
  padding: 2px 8px;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;

  &:hover {
    background: $primary60-20;
    color: $primary60;
  }

  &__text {
    max-width: 200px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__amount {
    color: $blue-grey10;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.buttons {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
