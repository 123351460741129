<template lang="pug">
.action-marketplace
  SectionHeader(:header="header")

  .action__body
    .action__section
      .marketplace__filters
        InputNumber(v-model="orderId" placeholder="ID заказа")
        InputNumber(v-model="sellerId" placeholder="ID продавца")
        InputNumber(v-model="buyerId" placeholder="ID покупателя")
        BaseSelect(
          v-model="orderStatus"
          :options="orderStatuses"
          with-empty-value
          placeholder="Статус заказа"
        )
      Button.marketplace__filters-btn(is-primary @click="newSearch") Искать

    .action__section
      .marketplace__ordersList(v-if="orders")
        .marketplace__order(v-for="order in orders" :key="order.id")
          OrderCard(:order="order")

  .pagination
    Pagination(:pagesCount="pagesCount" :perPage="pageSize" :currentPage="page" @pageChange="setPage" @perPageChange="setPerPage")
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import Pagination from '@/components/Marketplace/Pagination/Pagination'
import OrderCard from '@/components/Marketplace/OrderCard'
import InputNumber from "@/components/InputNumber/InputNumber";
import BaseSelect from "@/components/BaseSelect/BaseSelect";

export default {
  name: 'Marketplace',

  components: {
    BaseSelect,
    InputNumber,
    OrderCard,
    SectionHeader,
    Pagination,
  },

  data () {
    return {
      page: 0,
      pageSize: 12,
      orders: undefined,
      ordersAmount: 0,
      orderId: '',
      sellerId: '',
      buyerId: '',
      orderStatus: '',
      orderStatuses: [
        {
          title: 'Ожидает оплаты',
          value: 'created'
        },
        {
          title: 'Ожидает продавца',
          value: 'paid'
        },
        {
          title: 'В работе',
          value: 'in_progress'
        },
        {
          title: 'Ожидает одобрения',
          value: 'fulfilled'
        },
        {
          title: 'Выполнен (без отзыва)',
          value: 'completed'
        },
        {
          title: 'Выполнен (с отзывом)',
          value: 'completed_with_review'
        },
        {
          title: 'Отменён (без отзыва)',
          value: 'cancelled'
        },
        {
          title: 'Отменён (с отзывом)',
          value: 'cancelled_with_review'
        },
        {
          title: 'Отменён покупателем после оплаты',
          value: 'cancelled_after_payment'
        },
        {
          title: 'Отменён до оплаты',
          value: 'cancelled_before_payment'
        }
      ]
    }
  },

  computed: {
    header() {
      return {
        title: 'Заказы'
      }
    },

    pagesCount () {
      return Math.ceil((this.ordersAmount || 0) / this.pageSize)
    }
  },

  mounted () {
    this.getOrders()
  },

  methods: {
    async getOrders () {
      this.$loading.start()

      const params = {
        page: this.page + 1,
        size: this.pageSize,
        orderBy: 'id',
        orderByDirection: 'DESC'
      }

      if (this.orderId && this.orderId !== '' && +this.orderId > 0) {
        params.orderId = this.orderId
      }

      if (this.sellerId && this.sellerId !== '' && +this.sellerId > 0) {
        params.sellerId = this.sellerId
      }

      if (this.buyerId && this.buyerId !== '' && +this.buyerId > 0) {
        params.buyerId = this.buyerId
      }

      if (this.orderStatus && this.orderStatus !== '') {
        params.orderStatus = this.orderStatus
      }

      try {
        const { data } = await this.$axios.get(
            '/marketplace/orders-list',
            {
              params,
            }
        )

        this.orders = data.data.orders
        this.ordersAmount = data.data.count
      } catch (e) {
        alert('Ошибка получения списка заказов')
      }

      this.$loading.finish()
    },

    newSearch () {
      this.setPage(0)
    },

    setPage (pageNumber) {
      this.page = pageNumber
      this.getOrders()
    },

    setPerPage (perPage) {
      this.pageSize = perPage
      this.setPage(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.marketplace {
  &__order {
    height: 100%;
  }

  &__ordersList {
    color: $white;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-items: center;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__filters {
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &-btn {
      margin: 12px 0;
    }
  }
}
</style>
